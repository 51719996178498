@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./scss/dashboard.css";
@import "swiper/css";
@import "swiper/css/navigation";

@import "smart-webcomponents-react/source/styles/smart.default.css";
@import "Task.calendar.css";
@import "@splidejs/react-splide/css/core";
@import "@splidejs/react-splide/css";

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#__next {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
  color: #1f2937;
}

:root {
  --primary: #f17104;
  --smart-gantt-chart-task-fill-border-radius: 50px;
  --smart-table-indent: 15px;
  --smart-gantt-chart-project-color: var(--primary);
  --smart-font-family: "Nunito Sans", sans-serif;
  --smart-gantt-chart-task-color: #256eff;
  --smart-gantt-chart-task-default-height: 40px;
  --smart-gantt-chart-project-label-color-selected: #fff;
  --smart-gantt-chart-task-label-color-selected: #fff;
  --smart-gantt-chart-project-label-color: transparent;
  --smart-gantt-chart-task-label-color: transparent;
  --smart-multiline-text-box-default-height: 150px;
}

[id*="react-select-"]:focus {
  box-shadow: none !important;
}

[type="text"],
[type="text"]:focus,
select:focus,
textarea:focus {
  --tw-ring-color: var(--primary);
}

[class*="indicatorSeparator"] {
  display: none;
}

.css-1pahdxg-control {
  border-color: var(--primary) !important;
  box-shadow: 0 0 0 1px var(--primary) !important;
}

:root {
  --swiper-navigation-color: #fff;
  --swiper-navigation-size: 15px;
}

.swiper-button-next,
.swiper-button-prev {
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  background-color: rgba(255, 255, 255, 0.3);
  padding: 15px;
  border-radius: 100rem;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: var(--swiper-navigation-size);
}

.smart-table[freeze-header][virtualization] thead {
  background-color: #1f2937 !important;
}

.smart-gantt-chart .smart-table thead tr th,
.smart-gantt-chart .smart-table thead tr th.smart-table-select-all.freeze-near {
  background-color: #1f2937;
  color: #fff;
}

.smart-table thead th {
  font-weight: 400;
}

.smart-gantt-chart .withdraw {
  --smart-gantt-chart-milestone-color: rgb(43, 195, 190);
}

.smart-gantt-chart .delivery {
  --smart-gantt-chart-milestone-color: #ff4062;
}

.smart-gantt-chart .mantenance {
  --smart-gantt-chart-task-color: #fab900;
}

.smart-arrow-down::after,
.smart-arrow-left-first::after,
.smart-arrow-left::after,
.smart-arrow-right-last::after,
.smart-arrow-right::after,
.smart-arrow-up::after {
  color: var(--primary);
  font-size: 20px;
}

.custom-window-content {
  display: grid;
  grid-row-gap: 15px;
  padding: 15px;
}

.custom-window-content-section[disabled] {
  pointer-events: none;
  opacity: 0.25;
}

.custom-window-content-section:first-of-type label {
  font-size: 20px;
  font-weight: 600;
}

.custom-window-content-section.date-controls {
  display: grid;
  grid-row-gap: 5px;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5px;
  align-items: center;
}

.custom-window-content-section.date-controls .custom-window-content-section {
  display: grid;
  align-items: center;
  grid-column-gap: 5px;
  grid-template-columns: repeat(4, auto);
  justify-content: center;
}

.custom-window-content-section.date-controls
  .custom-window-content-section:first-of-type {
  grid-template-columns: repeat(3, 1fr);
}

.custom-window-content-section.date-controls
  .custom-window-content-section:last-of-type {
  grid-template-columns: repeat(1, 1fr);
}

.custom-window-content-section.buttons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-content: center;
  column-gap: 5px;
}

#cancelBtn {
  grid-column-start: 3;
}

#totalDate {
  white-space: nowrap;
  min-width: 100px;
}

.smart-window:not(.smart-container) {
  box-shadow: none;
  width: 740px;
  border-radius: 6px;
}

.pop-up-select-w-full,
.smart-multiline-text-box.smart-element {
  width: 100%;
}

[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  border-radius: 5px;
  border-color: none;
}

#dayInput {
  width: 150px;
  height: 42px;
}

.smart-text-box .smart-container {
  height: 100%;
}

button.smart-button {
  padding: 13px 16px;
}

@layer components {
  .btn-dropdown {
    @apply text-dark-jungle flex justify-between hover:bg-gray-100 px-4 py-3 m-2 rounded-sm text-sm leading-5 text-left;
  }

  .btn-dropdown-small {
    @apply text-dark-jungle flex justify-between hover:bg-gray-100 px-2 py-1 m-2 rounded-sm text-sm leading-5 text-left;
  }
}

.binnacle-textarea {
  min-height: 100px;
}

.binnacle-textarea__input:disabled {
  border-color: #e6e6e6;
}

.binnacle-textarea__input {
  border-color: #d1d5db;
}

.binnacle-textarea__input:focus {
  border-color: var(--primary);
}

.binnacle-textarea__suggestions {
  border-radius: 4px;
  top: 20px !important;
  box-shadow: 3px 3px 3px #b8bec5;
  max-height: 100px;
  overflow-y: scroll;
}

.binnacle-textarea__suggestions__item {
  padding: 5px 8px;
}

.binnacle-textarea__suggestions__item:hover {
  background-color: #d1d5db;
  border-radius: 4px;
}

.binnacle-textarea__highlighter strong {
  position: relative;
  z-index: 1;
  color: #256eff;
}

.binnacle-textarea__highlighter {
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
}

.Bhjc {
  background-color: transparent !important;
  padding: 0 !important;
  height: calc(100vh - 215px) !important;
}

.caQSEE {
  background-color: transparent !important;
}

.kOFDIq {
  max-height: calc(100vh - 300px) !important;
}

.capitalize-first::first-letter {
  text-transform: uppercase;
}

.supplierLeadCard {
  border-radius: 6px;
  border: 1px solid var(--subtle-border, rgba(107, 114, 128, 0.25));
  background: var(
    --card-bg,
    linear-gradient(173deg, #fff 0%, rgba(255, 255, 255, 0) 100%)
  );
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
  @apply p-3;
}

.innerSupplierLeadCard {
  border-radius: 6px;
  border: 1px solid var(--subtle-border, rgba(107, 114, 128, 0.25));
  background: var(
    --card-bg,
    linear-gradient(173deg, #fff 0%, rgba(255, 255, 255, 0) 100%)
  );
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
  @apply divide-x-2;
}

.summaryLeadCard {
  border-radius: 6px;
  border: 1px solid var(--subtle-border, rgba(107, 114, 128, 0.25));
  background: var(
    --card-bg,
    linear-gradient(173deg, #fff 0%, rgba(255, 255, 255, 0) 100%)
  );
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
  @apply divide-x-2;
}

.innerSupplierLeadCard > :not([hidden]) ~ :not([hidden]) {
  border: 0;
}

.innerSupplierLeadCard > table td {
  padding: 4px !important;
}

.table-child-row {
}

.table-child-row .first-td {
  position: relative;
  padding-left: 20px;
}

.table-child-row .first-td::before {
  content: "";
  position: absolute;
  left: 5px;
  top: -10px;
  width: 10px;
  height: 22px;
  border-bottom-left-radius: 5px;
  border-left: 1px solid gray;
  border-bottom: 1px solid gray;
}

.table-child-row--first .first-td::before {
  height: 15px;
  top: -2px;
}

.table-child-row--first {
  margin-top: 10px;
}

/* .splide:not( .is-overflow ) .splide__list {
  justify-content: center;
} */

.splide:not(.is-overflow) .splide__arrows {
  display: none;
}

.add-tabs-button {
  @apply w-fit place-self-end flex items-center px-2.5 py-1.5 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-safety-orange mt-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 hover:ring-2 hover:ring-offset-2 hover:ring-primary;
}

.add-tabs-button-secondary {
  @apply inline-flex justify-center rounded-md border border-primary bg-transparent py-1.5 px-2.5 text-sm font-medium text-primary shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 hover:ring-2 hover:ring-offset-2 hover:ring-primary;
}

.reset-style,
.reset-style * {
  all: revert;
}

.dashboard-selects [class*="-control"] {
  background-color: transparent !important;
}

.dashboard-selects [class*="-singleValue"],
.react-select-with-icon [class*="-singleValue"],
.dashboard-selects [class*="-placeholder"],
.react-select-with-icon [class*="-placeholder"] {
  padding-left: 38px !important;
}

.dashboard-charts-wrapper {
  height: calc(100vh - 190px);
  overflow-y: scroll;
}

.kpis-chart .apexcharts-xaxis-label {
  display: none;
}

.goals-donut-chart .apexcharts-tooltip .apexcharts-tooltip-series-group {
  background-color: rgba(30, 30, 30, 0.8) !important;
  color: white;
}

.custom-tooltip-apexchart {
  padding: 5px;
  text-align: right;
}

.custom-tooltip-apexchart li p {
  color: white !important;
}

.tooltip-color-legend {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 2px;
}

.tooltip-color-legend--total {
  background: rgb(255, 117, 0);
  background: linear-gradient(
    90deg,
    rgba(255, 117, 0, 1) 50%,
    rgba(254, 81, 113, 1) 100%
  );
}

@media screen and (max-width: 1280px) {
  .dashboard-selects [class*="-singleValue"],
  .react-select-with-icon [class*="-singleValue"],
  .dashboard-selects [class*="-placeholder"],
  .react-select-with-icon [class*="-placeholder"] {
    padding-left: 24px !important;
  }
}

.gm-style-iw-chr {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
}

.gm-style-iw.gm-style-iw-c {
  padding-top: 14px !important;
  width: 200px !important;
}
